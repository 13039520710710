import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import RelativeLink from "app/pages/.shared/RelativeLink";
import Hamburger from "app/pages/Header/Hamburger/Hamburger";
import { FormattedMessage } from "react-intl";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import "./ScrollSpyHeader.scss";
import "./nav-scroll-spy.scss";
import IconArrowDown from "app/pages/.shared/static/icons/IconArrowDown";

const MENUS_HASHES = {
	WE_LOVE: "we-love",
	ACCOMMODATION: "accommodation",
	USEFUL_INFORMATION: "useful-information",
};

const ScrollSpyHeader = ({
	showHeader,
	menuCollapsed,
	component,
	toggleAsideMenu,
	scrollPosition,
	toggleScrollSpyMenu,
	hideScrollSpyMenu,
	isAsideMenuOpened,
	ficheProduit,
	showHambuger,
	resolution,
}) => {
	const isScreenBelowMedium = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;

	const scrollSpyHeaderClass = classNames({
		"scroll-spy-header": true,
		"scroll-spy-header--pushed": isAsideMenuOpened,
		"scroll-spy-header--open": showHeader,
	});

	const scrollSpyNavClass = classNames({
		"scroll-spy-nav": true,
		"scroll-spy-nav--open": showHeader,
		"scroll-spy-nav--collapse": menuCollapsed,
	});

	const productUri = ficheProduit.uri;

	return isScreenBelowMedium ? (
		<React.Fragment>
			<div className={scrollSpyHeaderClass}>
				<div className="scroll-spy-header__hamburger">
					{!menuCollapsed && showHambuger && (
						<Hamburger isActive={isAsideMenuOpened} onToogle={toggleAsideMenu} />
					)}
				</div>
				<div className="scroll-spy-header__current" onClick={toggleScrollSpyMenu}>
					<FormattedMessage id={`header.nav.item.${scrollPosition}`} />
					<IconArrowDown height={15} width={15} style={{ marginLeft: "10px" }} />
				</div>
				<div className="scroll-spy-header__empty" />
			</div>
			<nav className={scrollSpyNavClass}>
				<ul className="scroll-spy-nav__list">
					<li className="scroll-spy-nav__item">
						<RelativeLink
							to={{
								pathname: `/${productUri}`,
								hash: `#${MENUS_HASHES.WE_LOVE}`,
							}}
							replace={true}
							onClick={hideScrollSpyMenu}
						>
							<FormattedMessage id="header.nav.item.we-love" />
						</RelativeLink>
					</li>
					<li className="scroll-spy-nav__item">
						<RelativeLink
							to={{
								pathname: `/${productUri}`,
								hash: `#${MENUS_HASHES.ACCOMMODATION}`,
							}}
							replace={true}
							onClick={hideScrollSpyMenu}
						>
							<FormattedMessage id="header.nav.item.accommodation" />
						</RelativeLink>
					</li>
					<li className="scroll-spy-nav__item">
						<RelativeLink
							to={{
								pathname: `/${productUri}`,
								hash: `#${MENUS_HASHES.USEFUL_INFORMATION}`,
							}}
							replace={true}
							onClick={hideScrollSpyMenu}
						>
							<FormattedMessage id="header.nav.item.useful-information" />
						</RelativeLink>
					</li>
				</ul>
			</nav>
		</React.Fragment>
	) : (
		<div className={scrollSpyHeaderClass}>
			<div className="wrapper">
				<div className="row">
					<nav className="nav">
						<ul className="nav__list">
							<li
								className={
									scrollPosition === MENUS_HASHES.WE_LOVE
										? "nav__item active"
										: "nav__item"
								}
							>
								<RelativeLink
									to={{
										pathname: `/${productUri}`,
										hash: `#${MENUS_HASHES.WE_LOVE}`,
									}}
									replace={true}
									onClick={hideScrollSpyMenu}
								>
									<FormattedMessage id="header.nav.item.we-love" />
								</RelativeLink>
							</li>
							<li
								className={
									scrollPosition === MENUS_HASHES.ACCOMMODATION
										? "nav__item active"
										: "nav__item"
								}
							>
								<RelativeLink
									to={{
										pathname: `/${productUri}`,
										hash: `#${MENUS_HASHES.ACCOMMODATION}`,
									}}
									replace={true}
									onClick={hideScrollSpyMenu}
								>
									<FormattedMessage id="header.nav.item.accommodation" />
								</RelativeLink>
							</li>
							<li
								className={
									scrollPosition === MENUS_HASHES.USEFUL_INFORMATION
										? "nav__item active"
										: "nav__item"
								}
							>
								<RelativeLink
									to={{
										pathname: `/${productUri}`,
										hash: `#${MENUS_HASHES.USEFUL_INFORMATION}`,
									}}
									replace={true}
									onClick={hideScrollSpyMenu}
								>
									<FormattedMessage id="header.nav.item.useful-information" />
								</RelativeLink>
							</li>
						</ul>
					</nav>
					<div className="scroll-spy-header__component">{component}</div>
				</div>
			</div>
		</div>
	);
};

ScrollSpyHeader.propTypes = {
	resolution: PropTypes.string,
	menuCollapsed: PropTypes.bool,
	showHambuger: PropTypes.bool,
	showHeader: PropTypes.bool,
	scrollPosition: PropTypes.string,
	toggleAsideMenu: PropTypes.func,
	hideScrollSpyMenu: PropTypes.func,
	component: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
	ficheProduit: PropTypes.object,
	toggleScrollSpyMenu: PropTypes.func,
	isAsideMenuOpened: PropTypes.bool,
};

ScrollSpyHeader.defaultProps = {
	ficheProduit: {},
};

export default React.memo(ScrollSpyHeader);
