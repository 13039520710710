import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./separator.scss";

class Separator extends React.PureComponent {
	render() {
		const { design = "left", margin = "wide" } = this.props;

		const separatorClassName = classNames({
			separator: true,
			"separator--wide-margin": margin === "wide",
			"separator--narrow-margin": margin === "narrow",
			"separator--left": design === "left",
			"separator--left-white": design === "left-white",
			"separator--middle": design === "middle",
			"separator--middle-dark": design === "middle-dark",
			"separator--full": design === "full",
			"separator--full-dark": design === "full-dark",
		});

		return <div className={separatorClassName} />;
	}
}

Separator.propTypes = {
	design: PropTypes.oneOf(["left", "left-white", "middle", "middle-dark", "full-dark", "full"]),
	margin: PropTypes.oneOf(["wide", "narrow"]),
};

export default registerStyle(style)(Separator);
