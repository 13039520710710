import {
	UPDATE_SCROLL_SPY_POSITION,
	COLLAPSE_SCROLL_SPY_MENU,
	HIDE_SCROLL_SPY_MENU,
	HIDE_SCROLL_SPY_HEADER,
	TOGGLE_SCROLL_SPY_MENU,
	SHOW_STICKY_DEAL,
	HIDE_STICKY_DEAL,
	SHOW_STICKY_PRICE,
	HIDE_STICKY_PRICE,
} from "../../../actionTypes";

export default function scrollSpyHeaderReducer(
	state = {
		showHeader: false,
		menuCollapsed: false,
		showStickyDeal: false,
		showStickyPrice: false,
		scrollPosition: "we-love",
	},
	action
) {
	switch (action.type) {
		case UPDATE_SCROLL_SPY_POSITION:
			return Object.assign({}, state, {
				showHeader: action.showHeader,
				scrollPosition: action.scrollPosition,
			});
		case COLLAPSE_SCROLL_SPY_MENU:
			return Object.assign({}, state, {
				menuCollapsed: true,
			});
		case HIDE_SCROLL_SPY_MENU:
			return Object.assign({}, state, {
				menuCollapsed: false,
			});
		case HIDE_SCROLL_SPY_HEADER:
			return Object.assign({}, state, {
				showHeader: false,
				menuCollapsed: false,
			});
		case TOGGLE_SCROLL_SPY_MENU:
			return Object.assign({}, state, {
				menuCollapsed: !state.menuCollapsed,
			});
		case SHOW_STICKY_DEAL:
			return Object.assign({}, state, {
				showHeader: true,
				showStickyDeal: true,
			});
		case HIDE_STICKY_DEAL:
			return Object.assign({}, state, {
				showStickyDeal: false,
			});
		case SHOW_STICKY_PRICE:
			return Object.assign({}, state, {
				showHeader: true,
				showStickyPrice: true,
			});
		case HIDE_STICKY_PRICE:
			return Object.assign({}, state, {
				showStickyPrice: false,
			});
		default:
			return state;
	}
}
