import React from "react";
import PropTypes from "prop-types";
import "./VideoPreviewPicture.scss";
import IconPlay from "app/pages/.shared/static/icons/IconPlay";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";

const IMG_SIZES = {
	small: { height: 550 },
	medium: { height: 550 },
	large: { height: 550 },
	xlarge: { height: 550 },
};

const VideoPreviewPicture = ({ previewPicture, thumbnail }) => {
	return (
		previewPicture && (
			<div className="video-preview-picture">
				<BackgroundProgressiveImg
					backgroundPosition="center"
					sizes={IMG_SIZES}
					src={previewPicture}
					thumbnail={thumbnail}
				/>
				{/* Le picto Play doit etre après BackgroundProgressiveImg sinon le picto disparait en fade puis rerapparait a chaque render */}
				<div className="video-preview-picture__play">
					<IconPlay height={50} width={50} />
				</div>
			</div>
		)
	);
};

VideoPreviewPicture.propTypes = {
	previewPicture: PropTypes.string,
	thumbnail: PropTypes.string,
};

export default React.memo(VideoPreviewPicture);
