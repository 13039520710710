import { withRouter } from "react-router-dom";
import { PureComponent } from "react";

/*
 * Pour repositionner en haut de page à chaque nouvelle page
 * @see https://reacttraining.com/react-router/web/guides/scroll-restoration
 */
class ScrollToTop extends PureComponent {
	componentDidUpdate(prevProps) {
		const pathname = this.props.location.pathname;

		if (
			pathname !== prevProps.location.pathname &&
			!pathname.includes("/account/sponsorship") &&
			!pathname.includes("/landing-page") &&
			!pathname.includes("/home")
		) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		return this.props.children;
	}
}

export default withRouter(ScrollToTop);
