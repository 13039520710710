import React from "react";
import ScrollSpyHeader from "./ScrollSpyHeader.jsx";
import {
	collapseScrollSpyMenu,
	hideScrollSpyHeader,
	hideScrollSpyMenu,
	toggleScrollSpyMenu,
	updateScrollSpyPosition,
} from "./ScrollSpyHeaderActionCreators";
import ScrollSpyPrice from "../../FicheProduit/components/ScrollSpyPrice/ScrollSpyPrice.jsx";
import { toggleAsideMenu } from "../../Header/menuActionCreators";
import { OFFER_CONTRACT_TYPES } from "app/constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { offerShape } from "../../../utils/propTypes";
import { isWhiteLabel } from "app/reducers/brandSelector";
import DealTimerContainer from "app/pages/.shared/Deal/DealTimer/DealTimerContainer";

class ScrollSpyHeaderContainer extends React.PureComponent {
	render() {
		const { showStickyDeal, showStickyPrice, ficheProduit, isInApp } = this.props;

		let component = false;

		const isRental = ficheProduit.contractType === OFFER_CONTRACT_TYPES.RENTAL;
		if (showStickyDeal) {
			component = (
				<DealTimerContainer
					includeOfferWithFlight={ficheProduit.includeOfferWithFlight}
					duration={ficheProduit.fromPriceType.numberOfNights}
					hour={ficheProduit.expiration.hour}
					day={ficheProduit.expiration.day}
					price={ficheProduit.fromPriceType.price}
					publicPrice={ficheProduit.fromPriceType.publicPrice}
					type={ficheProduit.fromPriceType.type}
					value={ficheProduit.fromPriceType.value}
					isRental={isRental}
				/>
			);
		} else if (showStickyPrice) {
			component = <ScrollSpyPrice ficheProduit={ficheProduit} isRental={isRental} />;
		}

		return isInApp ? false : <ScrollSpyHeader {...this.props} component={component} />;
	}
}

ScrollSpyHeaderContainer.propTypes = {
	isAsideMenuOpened: PropTypes.bool,
	isAsideProductFilterOpened: PropTypes.bool,
	showStickyDeal: PropTypes.bool,
	showStickyPrice: PropTypes.bool,
	ficheProduit: PropTypes.object,
	price: offerShape,
	scrollSpy: PropTypes.object,
	showHeader: PropTypes.bool,
	menuCollapsed: PropTypes.bool,
	isInApp: PropTypes.bool,
	scrollPosition: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		ficheProduit: state.ficheProduit,
		partnercode: state.partner.code,
		resolution: state.resolution,
		showHambuger: isWhiteLabel(state),
		isInApp: state.navigation.isInApp,
		...state.menu,
		...state.scrollSpy,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			updateScrollSpyPosition,
			hideScrollSpyHeader,
			hideScrollSpyMenu,
			collapseScrollSpyMenu,
			toggleAsideMenu,
			toggleScrollSpyMenu,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ScrollSpyHeaderContainer);
