import PropTypes from "prop-types";
import React from "react";

class Video extends React.PureComponent {
	render() {
		const { url = "", videoType, autoplay = 0 } = this.props;

		if (videoType) {
			return (
				<video width="100%" height="100%" controls autoPlay={autoplay}>
					<source src={url} type={videoType} />
				</video>
			);
		} else if (url && url.indexOf("http") !== -1) {
			// @see https://developers.google.com/youtube/player_parameters?hl=fr#Parameters
			const customizedUrl = `${url}?modestbranding=1&rel=0&iv_load_policy=3&autoplay=${autoplay}&playsinline=1&showinfo=0&controls=1&disablekb=1`;

			return (
				<iframe
					width="100%"
					height="100%"
					src={customizedUrl}
					frameBorder="0"
					allowFullScreen
					allow="autoplay" //	https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
				/>
			);
		}

		return false;
	}
}

Video.propTypes = {
	url: PropTypes.string.isRequired,
	videoType: PropTypes.string,
	autoplay: PropTypes.oneOf([0, 1]),
};

export default Video;
