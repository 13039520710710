import {
	UPDATE_SCROLL_SPY_POSITION,
	HIDE_SCROLL_SPY_HEADER,
	HIDE_SCROLL_SPY_MENU,
	COLLAPSE_SCROLL_SPY_MENU,
	TOGGLE_SCROLL_SPY_MENU,
	SHOW_STICKY_DEAL,
	HIDE_STICKY_DEAL,
	HIDE_STICKY_PRICE,
	SHOW_STICKY_PRICE,
} from "../../../actionTypes";

export const updateScrollSpyPosition = (position, showHeader) => {
	return {
		type: UPDATE_SCROLL_SPY_POSITION,
		scrollPosition: position,
		showHeader: showHeader,
	};
};

export const hideScrollSpyHeader = () => {
	return {
		type: HIDE_SCROLL_SPY_HEADER,
	};
};

export const hideScrollSpyMenu = () => {
	return {
		type: HIDE_SCROLL_SPY_MENU,
	};
};

export const collapseScrollSpyMenu = () => {
	return {
		type: COLLAPSE_SCROLL_SPY_MENU,
	};
};

export const toggleScrollSpyMenu = () => {
	return {
		type: TOGGLE_SCROLL_SPY_MENU,
	};
};

export const showStickyDeal = () => {
	return {
		type: SHOW_STICKY_DEAL,
	};
};

export const hideStickyDeal = () => {
	return {
		type: HIDE_STICKY_DEAL,
	};
};

export const hideStickyPrice = () => {
	return {
		type: HIDE_STICKY_PRICE,
	};
};
export const showStickyPrice = () => {
	return {
		type: SHOW_STICKY_PRICE,
	};
};
