import React from "react";
import VideoPreviewPicture from "app/pages/.shared/Video/VideoPreviewPicture";
import Video from "app/pages/.shared/Video/Video";
import PropTypes from "prop-types";

const previewContainerStyles = { height: "100%", width: "100%", cursor: "pointer" };

const VideoSwitchThumbnail = ({ videoType, videoUrl, videoPreviewUrl, videoThumbnail }) => {
	const [showVideo, setVideoVisibility] = React.useState(false);
	const onPreviewPictureClick = React.useCallback(() => setVideoVisibility(true), []);

	return showVideo ? (
		<Video autoplay={1} videoType={videoType} url={videoUrl} />
	) : (
		<div style={previewContainerStyles} onClick={onPreviewPictureClick}>
			<VideoPreviewPicture previewPicture={videoPreviewUrl} thumbnail={videoThumbnail} />
		</div>
	);
};

VideoSwitchThumbnail.propTypes = {
	videoType: PropTypes.string,
	videoUrl: PropTypes.string,
	videoPreviewUrl: PropTypes.string,
	videoThumbnail: PropTypes.string,
};

export default React.memo(VideoSwitchThumbnail);
