import { OPEN_SLIDESHOW, CLOSE_SLIDESHOW } from "app/actionTypes";
import { SLIDE_SHOW_PHOTO_TYPES } from "app/constants";

const initialState = {
	isSlideshowOpened: false,
	photosType: SLIDE_SHOW_PHOTO_TYPES.MULTIMEDIA,
};

export default function slideshowReducer(state = initialState, action) {
	switch (action.type) {
		case OPEN_SLIDESHOW:
			return Object.assign({}, state, {
				isSlideshowOpened: true,
				photosType: action.photosType,
			});
		case CLOSE_SLIDESHOW:
			return initialState;
		default:
			return state;
	}
}
