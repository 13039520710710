import React from "react";
import { FormattedMessage } from "react-intl";

import "./WhyJoinUs.scss";

// @todo : Supprimer les clés provisoires : general.reason.to.book.(title|texte).(1|2|3).new  lorsque les clés seront traduites
const WhyJoinUs = () => {
	return (
		<div className="why-join-us">
			<h1 className="why-join-us__title">
				<FormattedMessage id="home.reasons.title" />
			</h1>
			<div className="why-join-us-reasons">
				<div className="why-join-us-reasons__item">
					<div className="why-join-us-reasons__icon">
						<i className="icon icon--exception" />
					</div>
					<div className="why-join-us-reasons__title">
						<FormattedMessage id="general.reason.to.book.title.1" />
					</div>
					<div className="why-join-us-reasons__message">
						<FormattedMessage id="general.reason.to.book.texte.1" />
					</div>
				</div>

				<div className="why-join-us-reasons__item">
					<div className="why-join-us-reasons__icon">
						<i className="icon icon--economy" />
					</div>
					<div className="why-join-us-reasons__title">
						<FormattedMessage id="general.reason.to.book.title.2" />
					</div>
					<div className="why-join-us-reasons__message">
						<FormattedMessage id="general.reason.to.book.texte.2" />
					</div>
				</div>

				<div className="why-join-us-reasons__item">
					<div className="why-join-us-reasons__icon">
						<i className="icon icon--inedit" />
					</div>
					<div className="why-join-us-reasons__title">
						<FormattedMessage id="general.reason.to.book.title.3" />
					</div>
					<div className="why-join-us-reasons__message">
						<FormattedMessage id="general.reason.to.book.texte.3" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhyJoinUs;
