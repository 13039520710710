import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";
import PhotoCarousel from "../../.shared/PhotoCarousel.jsx";
import "./SlideShow.scss";

// @see https://reactcommunity.org/react-modal/#documentation
const style = {
	overlay: {
		backgroundColor: "rgba(43, 56, 63, .9)",
		zIndex: 5,
	},
};

class SlideShow extends React.PureComponent {
	constructor() {
		super();
		this.handleThumbnailClick = this.handleThumbnailClick.bind(this);
	}

	handleThumbnailClick() {
		// Lorsqu'une video youtube est lancée dans une iframe dans le carousel, on fait un focus ici pour permettre la navigation
		// avec les fleches du clavier et de fermer la carousel avec le bouton echap du clavier.
		this.contentRef.focus();
	}

	render() {
		const { isOpened, photos = [], close } = this.props;

		return (
			<Modal
				isOpen={isOpened}
				style={style}
				onRequestClose={close}
				className="slideshow__modal"
				overlayClassName="slideshow__overlay"
				contentRef={node => (this.contentRef = node)}
			>
				<div className="slideshow__close" onClick={close}>
					<i className="icon icon--cross-dark" />
				</div>
				<div className="slideshow">
					<PhotoCarousel
						isSlideshowMode={true}
						ref="slideshow"
						photos={photos}
						onThumbnailClick={this.handleThumbnailClick}
					/>
				</div>
			</Modal>
		);
	}
}

SlideShow.propTypes = {
	isOpened: PropTypes.bool,
	close: PropTypes.func,
	photos: PropTypes.array,
};

export default SlideShow;
